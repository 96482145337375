/**
 * Created by andrey on 10.08.18.
 */

var GameBase = function (level, options) {
    cleverapps.EventEmitter.call(this);

    Game.currentGame = this;

    this.levelContent = level.content;
    this.level = level;
    this.options = options || {};

    this.slot = cleverapps.GameSaver.getStoreSlot(this.level.episodeNo, this.level.levelNo);
    this.savedGame = this.loadSave();

    this.score = new Score(this.savedGame.score);
    this.moves = this.savedGame.moves || 0;
    this.timer = new GameTimer(this);
    this.counter = new Counter();
    
    this.counter.registerStage(10000, cleverapps.tutorial.startNextStep.bind(cleverapps.tutorial), cleverapps.tutorial);
    this.counter.registerStage(10001, this.startTutorial.bind(this), this);

    this.afterStartActions = this.options.afterStartActions || [];

    if (this.level.isRegular() && !this.level.isBonus()) {
        this.primaryMission = cleverapps.missionManager.findBySemaphore(Missions.SEMAPHORE_PRIMARY);
    }

    if (this.level.isRegular() && !this.hasDanger() && !this.level.isBonus()) {
        var mission = cleverapps.missionManager.findBySemaphore(Missions.SEMAPHORE_SECONDARY);

        if (mission && [Mission.TYPE_RAINBOW, Mission.TYPE_COLLECT_MARK, Mission.TYPE_BURN_NEARBY, Mission.TYPE_LETTER, Mission.TYPE_BUTTERFLY].includes(mission.type)) {
            this.secondaryMission = mission;
        }
    }

    this.rewards = {};

    if (this.secondaryMission) {
        this.rewards[GameBase.REWARD_SECONDARY] = 0;
    }

    if (this.primaryMission) {
        this.rewards.mission = {
            missionType: this.primaryMission.type,
            amount: this.primaryMission.getLevelReward(this.level.getTag())
        };
    }

    if (this.level.isRegular() && cleverapps.exp.isAvailable()) {
        this.rewards[GameBase.REWARD_EXP] = this.level.isHard() ? GameBase.HARD_LEVEL_EXP_PRIZE : GameBase.EXP_PRIZE;
    }

    if (this.level.isRegular() && !this.level.isBonus() && cleverapps.stickersBook && cleverapps.stickersBook.isAvailable() && !cleverapps.stickersBook.isCompleted()) {
        this.rewards[GameBase.REWARD_STICKERS] = cleverapps.stickersBook.getLevelReward();
    }

    this.setBasicReward();

    this.rewards[GameBase.REWARD_HARD] = 0;

    if (this.level.isDanger()) {
        this.rewards[GameBase.REWARD_HARD] = GameBase.DANGER_REWARD;
    }

    var lantern = Lantern.Get();
    if (lantern && lantern.isActive(this.level)) {
        this.rewards.lantern = lantern.savedStreak >= Lantern.getMaxStreak() ? 0 : 1;
        lantern.onStart();
    }

    cleverapps.missionManager.getRunningMissions().forEach(function (mission) {
        if (mission.logic && mission.logic.addGameReward) {
            mission.logic.addGameReward();
        }
    });

    if (this.savedGame.rewards) {
        this.rewards = cleverapps.override(this.rewards, this.savedGame.rewards);
    }

    if (this.levelWithTutorial()) {
        cleverapps.Random.seed(this.level.getHumanReadableNumber() - 1);
    } else {
        cleverapps.Random.randomSeed();
    }

    this.outcome = GameBase.OUTCOME_UNKNOWN;

    if (cleverapps.flyingAd) {
        cleverapps.flyingAd.setGame();
    }

    this.counter.turnOff();

    this.boatswain = new Boatswain(level);

    this.counter.registerStage(-2, this.begin.bind(this));
    this.counter.registerStage(-1, cleverapps.availableMove.pause.bind(cleverapps.availableMove));
    this.counter.registerStage(1000, this.animateOutcome.bind(this));
    this.counter.registerStage(1001, function () {
        cleverapps.availableMove.resume();
    });

    if (typeof Prolongation !== "undefined") {
        this.prolongation = new Prolongation();
        this.prolongation.on("acceptOffer", this.onAcceptProlongationOffer.bind(this), this);
    }

    if (cleverapps.lives && cleverapps.lives.amount > cleverapps.lives.getMaxLives()) {
        this.moreThenMaxRegenLife = true;
    }

    this.takeLife(true);
};

GameBase.prototype = Object.create(cleverapps.EventEmitter.prototype);
GameBase.prototype.constructor = GameBase;

GameBase.prototype.animateOutcome = function () {
    if (this.outcome === GameBase.OUTCOME_UNKNOWN || cleverapps.focusManager.isFocused()) {
        return;
    }

    if (this.goalCounter && this.goalCounter.isActive()) {
        return;
    }

    this.trigger("animateOutcome", this.outcome);
};

GameBase.prototype.getMode = function () {
    return GameBase.MODE_REGULAR;
};

GameBase.prototype.shouldSkipVictoryWindow = function () {
    return this.level.content.skipVictoryWindow;
};

GameBase.prototype.levelWithTutorial = function () {
    if (cleverapps.config.editorMode) {
        return false;
    }

    if (cleverapps.isKnockoutGame()) {
        return false;
    }

    if (this.levelContent.tutorial) {
        if (this.level.isBonusWorldLevel()) {
            return true;
        }

        if (cleverapps.user.isPassedAll() && this.level.isPassedLevel()) {
            return false;
        }

        if (this.level.isRegular()) {
            return true;
        }
    }
    return false;
};

GameBase.prototype.displayTutorial = function (f) {
    f();
};

GameBase.prototype.hasBegan = function () {
    if (this.hasDanger()) {
        return true;
    }
    if (["battlefield"].includes(cleverapps.config.type)) {
        return true;
    }
    if (cleverapps.config.type === "match3") {
        return this.beginMoves !== this.moves;
    }
    if (["tile3", "klondike", "solitaire"].includes(cleverapps.config.type) && Boolean(this.move)) {
        return true;
    }

    return this.moves > 0 || this.getPercentOfCompletion() > 0;
};

GameBase.prototype.begin = function (force) {
    if (!this.began && (this.hasBegan() || force)) {
        this.began = true;

        if (this.level.isRegular() || this.level.isBonusWorldLevel()) {
            if (levels.user.isUniqueStart(this.level.episodeNo, this.level.levelNo, this.level.content.version)) {
                this._churnActivated();
            }
        }

        cleverapps.centerHint.finishCenterHint();
    }
};

GameBase.prototype.setBasicReward = function () {
    this.basicReward = 0;
    if (![Meta.HOMEFIX, Meta.SIMPLE, Meta.SHORTMETA, Meta.HOSE, Meta.FARM].includes(cleverapps.meta.getType())) {
        return;
    }
    if (cleverapps.config.type === "battlefield") {
        return;
    }
    if (this.rewards[GameBase.REWARD_EXP]) {
        return;
    }
    if (this.level.isBonus() || this.level.isBonusRound()) {
        return;
    }

    if (this.level.isRegular() || this.level.isDailyCup() || this.level.isAdsLevel()
        || this.level.isBonusWorldLevel() || this.level.isWeeklyTournamentLevel() || cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        this.basicReward = RewardsConfig.LevelBasicReward[this.level.getTag() || "default"];
    }
};

GameBase.prototype.addBasicReward = function () {
    var currency = cleverapps.config.soft ? GameBase.REWARD_SOFT : GameBase.REWARD_HARD;

    if (currency === GameBase.REWARD_HARD) {
        this.addHardReward(this.basicReward);
    } else {
        this.addSoftReward(this.basicReward);
    }
};

GameBase.prototype.showMessage = function (message, callback) {
    var delay = 200;
    var duration = 1400;

    cleverapps.timeouts.setTimeout(function () {
        this.trigger("showMessage", message);
    }.bind(this), delay);

    cleverapps.timeouts.setTimeout(callback, delay + duration);
};

GameBase.prototype.showStartGameMessage = function (f) {
    if (this.level.isHard()) {
        this.showMessage("message.hardLevel", f);
    } else {
        f();
    }
};

GameBase.prototype._churnActivated = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.UNIQUE_START, {
        hash: this.level.hash
    });
};

GameBase.prototype.setTimeout = function (callback, timeout) {
    return cleverapps.timeouts.setTimeout(function () {
        if (this.stopped) {
            return;
        }

        callback();
    }.bind(this), timeout);
};

GameBase.prototype.getInfo = function () {
    return {
        rewards: this.rewards
    };
};

GameBase.prototype.getInitialInfo = function () {
    return {};
};

GameBase.prototype.storeSave = function () {
    if (this.slot !== undefined) {
        var info = this.getInfo();
        console.log("storeSave");
        console.log(info);

        cleverapps.GameSaver.saveInfo(this.slot, info);
    }
};

GameBase.prototype.eraseSave = function () {
    cleverapps.GameSaver.removeSave(this.slot);
};

GameBase.prototype.loadSave = function () {
    if (this.slot !== undefined) {
        var stored = cleverapps.GameSaver.load(this.slot) || {};

        if (Object.keys(stored).length > 0) {
            return stored;
        }
    }

    return this.getInitialInfo();
};

GameBase.prototype.getDailyCupStars = function () {
    return 1;
};

GameBase.prototype.initPool = function () {

};

GameBase.prototype.stop = function () {
    this.counter.turnOff();
    this.timer.stop();
    levels.FPS.stop();

    runCleaners(this);

    cleverapps.userStatus.destructor();
    cleverapps.menuBar.stopItems();
    cleverapps.exclamation.remove();

    this.stopped = true;
    this.trigger("stop");
    this.purge();
};

GameBase.prototype.bonusByLeftMoves = function (f) {
    this.animateBonus(f);
};

GameBase.prototype.needBonusByLeftMoves = function () {
    return false;
};

GameBase.prototype.animateBonus = function (f) {
    f();
};

GameBase.prototype.leave = function (f) {
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        f();
        return;
    }

    if (this.stillNoPenalty()) {
        f();
        this.giveUp();
        return;
    }

    if (this.level.isBonus()) {
        f();
        this.win();
        return;
    }

    new ConfirmExitWindow({
        action: this.lose.bind(this)
    });
    cleverapps.focusManager.onceNoWindowsListener = f;
};

GameBase.prototype.takeLife = function (silent) {
    if (cleverapps.unlimitedLives && !cleverapps.unlimitedLives.checkBuyed()) {
        if (this.level.isDailyCup() && !cleverapps.dailyCup.withLives()) {
            return;
        }
        cleverapps.lives.take(silent);
    }
};

GameBase.prototype.giveLife = function () {
    if (cleverapps.unlimitedLives && !cleverapps.unlimitedLives.checkBuyed()) {
        if (this.level.isDailyCup() && !cleverapps.dailyCup.withLives()) {
            return;
        }

        if (this.moreThenMaxRegenLife || cleverapps.lives.amount < cleverapps.lives.getMaxLives()) {
            cleverapps.lives.give();
        }
    }
};

GameBase.prototype.setOutcome = function (outcome) {
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }
    this.outcome = outcome;

    if (this.boatswain) {
        this.boatswain.setOutcome(outcome);
    }

    cleverapps.user.incProgressCompare(outcome === GameBase.OUTCOME_VICTORY ? 30 : 1);

    if (cleverapps.config.debugMode) {
        console.log("OUTCOME: " + outcome);
    }

    BeforeOutcomeAction.call(this);
    this.trigger("outcome", outcome);
    this.counter.trigger();
};

GameBase.prototype.win = function () {
    this.setOutcome(GameBase.OUTCOME_VICTORY);
};

GameBase.prototype.lose = function () {
    this.setOutcome(GameBase.OUTCOME_LOST);
};

GameBase.prototype.giveUp = function () {
    this.setOutcome(GameBase.OUTCOME_GAVEUP);
};

GameBase.prototype.incCounter = function (f) {
    if (!cleverapps.config.editorMode) {
        this.counter.turnOn();
    }
    this.counter.inc();
    f();
};

GameBase.prototype.decCounter = function (f) {
    this.counter.dec();
    cleverapps.userStatus.reportUserAction();
    f();
};

GameBase.prototype.listIntroActions = function () {
    return [
        this.incCounter.bind(this),
        this.startBoosters.bind(this),
        this.showScreen.bind(this),
        this.restoreProgressUpdate.bind(this),
        this.runBeforeStartActions.bind(this),
        this.showStartGameMessage.bind(this),
        this.decCounter.bind(this),
        this.startIntroTutorial.bind(this),
        this.startOldTutorial.bind(this),
        this.runAfterStartActions.bind(this)
    ];
};

GameBase.prototype.startIntroTutorial = function (f) {
    if (cleverapps.tutorial) {
        cleverapps.tutorial.startScenario();
    }
    f();
};

GameBase.prototype.startOldTutorial = function (f) {
    if (this.levelWithTutorial()) {
        this.displayTutorial(f);
    } else {
        f();
    }
};

GameBase.prototype.startTutorial = function () {
    if (!cleverapps.tutorial.isActive() && !cleverapps.focusManager.isFocused()) {
        cleverapps.tutorial.startScenario();
    }
};

GameBase.prototype.startBoosters = function (f) {
    for (var id in cleverapps.boosters.boosters) {
        cleverapps.boosters.boosters[id].onGameStarted();
    }
    f();
};

GameBase.prototype.showScreen = function (f) {
    f();
};

GameBase.prototype.restoreProgressUpdate = function (f) {
    cleverapps.restoreProgress.update();
    f();
};

GameBase.prototype.runBeforeStartActions = function (f) {
    this.trigger("beforeStartActions");
    f();
};

GameBase.prototype.useLives = function () {
    return cleverapps.lives;
};

GameBase.EXP_PRIZE = 1;
GameBase.HARD_LEVEL_EXP_PRIZE = 3;

GameBase.DANGER_REWARD = 15;

GameBase.REWARD_SECONDARY = "clover";
GameBase.REWARD_EXP = "exp";
GameBase.REWARD_HARD = "hard";
GameBase.REWARD_BOOSTERS = "boosters";
GameBase.REWARD_SOFT = "soft";
GameBase.REWARD_STICKERS = "stickers";

GameBase.OUTCOME_UNKNOWN = undefined;
GameBase.OUTCOME_VICTORY = 1;
GameBase.OUTCOME_LOST = 2;
GameBase.OUTCOME_GAVEUP = 3;

GameBase.MODE_REGULAR = "regular";
GameBase.MODE_HIGHSCORE = "highscore";

GameBase.prototype.stillNoPenalty = function () {
    if (cleverapps.isRumble()) {
        return false;
    }

    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return false;
    }

    if (this.bonusTimer) {
        return false;
    }

    return !this.hasBegan();
};

GameBase.prototype.getMissionType = function () {
    if (this.secondaryMission) {
        return this.secondaryMission.type;
    }

    return undefined;
};

GameBase.prototype.addClover = function (missionType, amount, silent) {
    if (this.getMissionType() === missionType) {
        this.rewards[GameBase.REWARD_SECONDARY] = (this.rewards[GameBase.REWARD_SECONDARY] || 0) + amount;
        if (!silent) {
            this.trigger("rewardClover");
        }
    }
};

GameBase.prototype.addHardReward = function (amount, silent) {
    this.rewards[GameBase.REWARD_HARD] = (this.rewards[GameBase.REWARD_HARD] || 0) + amount;
    if (!silent) {
        this.trigger("rewardHard", amount);
    }
};

GameBase.prototype.addSoftReward = function (amount, options) {
    this.rewards[GameBase.REWARD_SOFT] = (this.rewards[GameBase.REWARD_SOFT] || 0) + amount;

    this.trigger("rewardSoft", amount, options);
};

GameBase.prototype.logCurrencyReward = function () {
    var currency = cleverapps.config.soft ? GameBase.REWARD_SOFT : GameBase.REWARD_HARD;

    if (this.basicReward > 0) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.EARN.LEVEL_BASE_REWARD + "_" + currency, { value: this.basicReward });
    }

    var extraReward = this.rewards[currency] - this.basicReward;
    if (extraReward > 0) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.EARN.LEVEL_EXTRA_REWARD + "_" + currency, { value: extraReward });
    }
};

GameBase.prototype.runAfterStartActions = function (cb, silent) {
    var actions = [
        function (f) {
            if (this.level.isRegular()) {
                levels.FPS.run(this.level.episodeNo, this.level.levelNo);
            }
            f();
        }.bind(this)
    ];

    if (!silent) {
        actions = actions.concat(this.afterStartActions);
    }

    cleverapps.focusManager.compound(cb, actions);
};

GameBase.prototype.getPercentOfCompletion = function () {
    return 0;
};

GameBase.prototype.onAcceptProlongationOffer = function (offer) {
    if (offer.reward === Prolongation.REWARDS.MOVES) {
        this.setMoves(offer.moves);
    }
};

GameBase.prototype.getProlongationMoves = function () {
    return 5;
};

GameBase.prototype.getNormalizedLevelReward = function (rewardConfig, benchmark) {
    var levelTag = this.level.getTag();
    var rewardsAmount = rewardConfig[levelTag] || rewardConfig.default;
    var avgLevelFinished = GameBase.AVG_LEVELS_FINISHED[cleverapps.config.type];

    if (!avgLevelFinished) {
        return rewardsAmount;
    }

    var multiplier = GameBase.AVG_LEVELS_FINISHED[benchmark] / avgLevelFinished;
    multiplier = Math.min(multiplier, 3);
    rewardsAmount *= multiplier;

    var integerPart = Math.floor(rewardsAmount);
    var fractionalPart = rewardsAmount - integerPart;
    var randomChance = Math.random();
    var finalReward = (fractionalPart > randomChance) ? integerPart + 1 : integerPart;
    return finalReward;
};

GameBase.prototype.hasDanger = function () {
    return this.level.isDanger();
};

GameBase.prototype.needBonusReward = function () {
    return false;
};

GameBase.prototype.needDifficultyHint = function () {
    if (cleverapps.tutorial.getAvailableScenario() || this.levelWithTutorial()) {
        return false;
    }

    if (!this.level.isRegular() && !this.level.isHard()) {
        return false;
    }

    if (cleverapps.config.type === "board" && !this.emptySave) {
        return false;
    }

    if (Game.currentGame.getMode() === GameBase.MODE_HIGHSCORE) {
        return false;
    }

    return this.level.isHard() || Math.random() < 0.25;
};

GameBase.prototype.calcCompletePercent = function (level) {
    var randomDeviation = cleverapps.Random.random(0, 5, level);
    var percent = 100 * Math.exp(-0.0005 * level) * ((0.1 * Math.cos(0.075 * level) + 0.85)) + randomDeviation;
    return Math.round(percent * 10) / 10;
};

GameBase.LEVEL_WITH_COINS_AVAILABLE = {
    level: 0.93
};

GameBase.AVG_LEVELS_FINISHED = {
    board: 5.08,
    match3: 4.95,
    solitaire: 7.32,
    tile3: 3.35,
    blocks: 4.95,
    differences: 8.47
};
